<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      @keydown="executeCloseModalTransferMaterials"
      @click:outside="executeCloseModalTransferMaterials"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>Registrar transferencia de materiales</h5>
        </div>
        <hr />
        <v-spacer></v-spacer>
        <v-card-text class="pb-0">
          <v-container>
            <v-row align="start" align-content="start">
              <v-col cols="12" md="6" sm="12" class="pb-0 mt-1">
                <v-autocomplete
                  v-model="form.mobile_warehouse"
                  :items="mobileWarehouses"
                  @input="getWarehouseMaterials"
                  class="mb-2"
                  item-value="code"
                  item-text="name_code"
                  label="Bodega móvil"
                  hide-details
                ></v-autocomplete>
                <small class="text-info" v-if="!form.mobile_warehouse">
                  Selecciona una Bodega Móvil para buscar los materiales
                  disponibles
                </small>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pb-0 mt-1">
                <v-autocomplete
                  v-model="form.project"
                  clearable
                  :items="projects.items ? projects.items : []"
                  hide-details
                  @input="getStagesFromProject()"
                  @update:search-input="getProjectsData"
                  @click:clear="getProjectsData"
                  no-data-text="Sin proyectos"
                  :loading="loadingProjects"
                  item-text="name_code"
                  label="Bodega Destino"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pb-0">
                <v-autocomplete
                  label="Etapa del proyecto"
                  v-model="form.sap_stage"
                  :items="availableStagesProjects"
                  :disabled="
                    !availableStagesProjects || !availableStagesProjects.length
                  "
                  item-value="sap_code"
                  item-text="name"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-data-table
              class="px-1 elevation-1"
              v-model="materialsSelected"
              :headers="tableHeaders"
              :items="
                materialsFromMobileWarehouse.items
                  ? materialsFromMobileWarehouse.items
                  : []
              "
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              :loading="loadingMaterials"
              loading-text="Cargando materiales"
              show-select
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
              @toggle-select-all="updateQuantityRowSelected"
              @item-selected="updateQuantityRowSelected"
            >
              <template v-slot:top>
                <v-toolbar flat class="pt-5">
                  <v-text-field
                    label="Busqueda"
                    v-model="filters.filter"
                    @input="getMaterials(1)"
                    :disabled="!form.mobile_warehouse"
                    class="w-25 mx-4"
                    clearable
                    placeholder="Buscar material"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-autocomplete
                    class="w-25"
                    @change="($event) => getMaterials(1)"
                    v-model="filters.items"
                    :items="[5, 10, 15, 20, 25, 'Todos']"
                    label="Items por página"
                  >
                  </v-autocomplete>
                  <v-divider class="mx-5" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <div class="d-flex align-items-center">
                    <p class="mx-3 text-h6">
                      <small class="pull-right">
                        Seleccionados: {{ materialsSelected.length }}
                      </small>
                    </p>
                  </div>
                </v-toolbar>
              </template>
              <template v-slot:item.code="{ item }">
                <span style="font-size: 12px">{{ item.code }}</span>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="my-0 text-left" style="font-size: 12px">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.available="{ item }">
                <p class="my-0 text-right" style="font-size: 12px">
                  {{ item.available_quantity }}
                </p>
              </template>
              <template v-slot:item.units="{ item }">
                <p class="my-0 text-center" style="font-size: 12px">
                  {{ item.measurement_unit.name }}
                </p>
              </template>
              <template v-slot:item.request="{ item }">
                <v-text-field
                  v-if="materialIsSelected(item)"
                  type="number"
                  :min="1"
                  :max="item.available_quantity"
                  class="pt-0 mt-0"
                  @input="setMaterialRequestValue($event, item)"
                  :error="
                    materialIsSelected(item)
                      ? materialIsSelected(item).request >
                        item.available_quantity
                      : false
                  "
                  :value="
                    materialIsSelected(item) &&
                    materialIsSelected(item).request !== undefined
                      ? materialIsSelected(item).request
                      : item.available_quantity
                  "
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
            <div class="d-flex justify-content-end pt-4">
              <v-pagination
                v-model="filters.page"
                :length="
                  materialsFromMobileWarehouse.total_pages
                    ? materialsFromMobileWarehouse.total_pages
                    : 1
                "
                :total-visible="8"
              ></v-pagination>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="executeCloseModalTransferMaterials"
          >
            <small> Cancelar </small>
          </v-btn>
          <v-btn
            color="teal accent-4"
            class="text-white"
            :loading="loading"
            :disabled="materialsSelected.length < 1"
            @click="validateMaterialsSelectedAndSend"
          >
            <small class="text-white"> Registrar transferencia </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import {
  TYPE_REQUESTS,
  CODE_SAP_STAGE_EXECUTION,
} from "@/constants/requestMaterial";
import swal2 from "sweetalert2";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateTransferMaterials: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      loadingProjects: false,
      debounce: null,
      debounceProjects: null,
      debounceTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
      },
      form: {
        project: null,
        sap_stage: null,
        mobile_warehouse: null,
      },
      materialsSelected: [],
      tableHeaders: [
        {
          text: "Código",
          align: "start",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          align: "start",
          width: "70%",
          sortable: false,
          value: "name",
        },
        {
          text: "Unidad",
          align: "start",
          width: "5%",
          sortable: false,
          value: "units",
        },
        {
          text: "Disponible",
          align: "end",
          width: "5%",
          sortable: false,
          value: "available",
        },
        {
          text: "Cantidad",
          align: "center",
          width: "15%",
          sortable: false,
          value: "request",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      updateStockMobileWarehouses:
        "mobileWarehouse/updateStockMobileWarehouses",
      getMaterialsFromMobileWarehouse:
        "mobileWarehouse/getMaterialsFromMobileWarehouse",
      clearMaterialsFromMobileWarehouse:
        "mobileWarehouse/clearMaterialsFromMobileWarehouse",
      sendCreateTransferMaterials: "mobileWarehouse/createTransferMaterials",
      clearAvailableStages: "requestMaterialsSap/clearAvailableStages",
      getStagesAvailableFromProject:
        "requestMaterialsSap/getStagesAvailableFromProject",
      getProjects: "requestMaterialsSap/getProjects",
    }),
    async getWarehouseMaterials() {
      this.resetMaterialsSelected();
      await this.updateStockMobileWarehouses({
        warehouse_code: this.form.mobile_warehouse?.split(" - ")[0],
      });
      await this.getMaterials(1);
    },
    async getStagesFromProject() {
      if (this.form.project) {
        const res = await this.getStagesAvailableFromProject({
          project_code: this.form.project?.split(" - ")[0],
        });
        this.form.sap_stage = CODE_SAP_STAGE_EXECUTION;
      } else {
        this.form.sap_stage = CODE_SAP_STAGE_EXECUTION;
        this.clearAvailableStages();
      }
    },
    executeCloseModalTransferMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.clearMaterialsFromMobileWarehouse();
        this.resetForm();
        this.resetMaterialsSelected();
        this.closeModalCreateTransferMaterials($event);
      }
    },
    resetForm() {
      this.filters.filter = "";
      this.form.project = null;
      this.form.mobile_warehouse = null;
      this.form.sap_stage = null;
    },
    resetMaterialsSelected() {
      this.materialsSelected = [];
    },
    async getMaterials(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        self.filters.page = page;
        await self.getMaterialsFromMobileWarehouse({
          page: self.filters.page,
          filter: self.filters.filter ? self.filters.filter : undefined,
          items:
            self.filters.items !== "Todos" ? self.filters.items : undefined,
          warehouse_code: self.form.mobile_warehouse?.split(" - ")[0],
        });
      }, self.debounceTime);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.form.project !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function () {
          await self.getProjects({
            is_residential: true,
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    materialIsSelected(material) {
      return this.materialsSelected.find((item) => item.id === material.id);
    },
    updateQuantityRowSelected({ item, items, value }) {
      if (value) {
        let materials = items ? [...items] : [item];
        materials.forEach((material) => {
          this.materialsSelected.push(material);
          this.setMaterialRequestValue(material.available_quantity, material);
        });
      }
    },
    setMaterialRequestValue(value, material) {
      let materials = [...this.materialsSelected];
      materials.forEach((item) => {
        if (item.id === material.id) {
          item.request = value;
        }
      });
      this.materialsSelected = materials;
    },
    validateMaterialsSelectedAndSend() {
      let validMaterials = true;
      let self = this;
      this.materialsSelected.forEach((material) => {
        if (
          isNaN(material.request) ||
          Number(material.request) > material.available_quantity
        ) {
          validMaterials = false;
        }
      });
      if (!validMaterials) {
        return this.$swal({
          icon: "error",
          title: "Error",
          text: "Las cantidades a transferir deben ser menores a la cantidad disponibles de material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 5000,
          timerProgressBar: true,
        });
      }
      if (!this.form.project) {
        return this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "El proyecto es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      swal2
        .fire({
          icon: "info",
          title: `¿Deseas registrar esta transferencia de material?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Registrar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.sendTransferMaterials();
          }
        });
    },
    async sendTransferMaterials() {
      this.loading = true;
      const response = await this.sendCreateTransferMaterials({
        request_type: TYPE_REQUESTS[0].name,
        comment: `Transferencia de materiales de Bodega Móvil ${this.form.mobile_warehouse} a Proyecto ${this.form.project}`,
        project: this.form.project.split(" - ")[0],
        materials: this.materialsSelected.reduce(
          (finalObject, currentObject) => {
            return {
              ...finalObject,
              [currentObject.code]: Number(currentObject.request),
            };
          },
          {}
        ),
        user: this.user.email, //leoramirez322@gmail.com,
        origin: this.form.mobile_warehouse,
        sap_stage: this.form.sap_stage,
      });
      if (response.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Transferencia de materiales creada con éxito",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        this.$emit("transferMaterialsCreated");
        this.resetMaterialsSelected();
        this.resetForm();
      }
      if (!response || response.status == 500) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 400) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: response.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
      this.closeModalCreateTransferMaterials(true);
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loadingMaterials: "mobileWarehouse/loadingMaterials",
      materialsFromMobileWarehouse:
        "mobileWarehouse/materialsFromMobileWarehouse",
      projects: "requestMaterialsSap/projects",
      availableStagesProjects: "requestMaterialsSap/availableStagesProjects",
      mobileWarehouses: "mobileWarehouse/mobileWarehouses",
    }),
    getCurrentProjectFromCode() {
      if (this.form.project && this.projects.items) {
        return this.projects.items.find(
          (item) => item.code == this.form.project?.split(" - ")[0]
        );
      }
      return null;
    },
  },
  watch: {
    "filters.page": function (page) {
      this.getMaterials(page);
    },
    projects(newProjects) {
      if (newProjects.items) {
        this.getStagesFromProject();
      }
    },
  },
};
</script>

<style></style>
